<template>
    <v-container
        v-if="mostUsedExist"
        class="py-0"
        :class="{
            'px-0': $vuetify.breakpoint.smAndDown,
            'px-4': $vuetify.breakpoint.mdAndUp
        }"
    >
        <v-row
            :class="{
                'mb-2 mt-0': $vuetify.breakpoint.mdAndUp,
                'mb-4': $vuetify.breakpoint.smAndDown
            }"
        >
            <v-col cols="12" class="ma-n1 pa-0 mb-n3">
                <v-subheader class="text-uppercase">Most Used</v-subheader>
            </v-col>
            <v-col
                v-for="company in renderableMostUsed"
                :key="company.id"
                cols="12"
                :md="$vuetify.breakpoint.mdAndDown ? 6 : 4"
                :class="{ 'mb-0 mt-2 py-0': $vuetify.breakpoint.smAndDown }"
            >
                <company-card
                    :company="company"
                    flat
                    style="cursor: pointer"
                    @click.native="select(company)"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { CompanyCard } from '@/components/CompanyCard';

import type { Company } from '@/types/Company';

const MostUsedOptions = Vue.extend({
    name: 'MostUsed',
    props: {
        companies: {
            type: Array as PropType<Partial<Company>[]>,
            default(): Company[] {
                return [];
            }
        }
    }
});

@Component({
    components: {
        CompanyCard
    }
})
export default class MostUsed extends MostUsedOptions {
    get mostUsedExist() {
        return Boolean(this.companies.length);
    }

    get renderableMostUsed() {
        return this.$vuetify.breakpoint.mdAndDown
            ? this.companies.slice(0, 2)
            : this.companies;
    }

    select(company: Partial<Company>) {
        this.$emit('select', company);
    }
}
</script>
