import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"py-0",class:{
        'px-0': _vm.$vuetify.breakpoint.smAndDown,
        'px-4': _vm.$vuetify.breakpoint.mdAndUp
    }},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('a-text-input',{ref:"input",attrs:{"label":"Search By Company Name","field":"Company Name","rules":"min:4","clearable":""},on:{"input":_vm.update},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }