<template>
    <v-card
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row class="ma-0">
            <v-col cols="12" lg="9" xl="6" class="pa-0">
                <a-form ref="form" :submit="save">
                    <v-card-title class="mb-4">Security</v-card-title>

                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="password_old"
                                    label="Old Password"
                                    vid="password_old"
                                    rules="required"
                                    :type="typeFor('password_old')"
                                    :append-icon="iconFor('password_old')"
                                    @click:append="toggle('password_old')"
                                />
                            </v-col>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="password_new"
                                    label="New Password"
                                    vid="password_new"
                                    rules="required|min:4"
                                    :type="typeFor('password_new')"
                                    :append-icon="iconFor('password_new')"
                                    @click:append="toggle('password_new')"
                                />
                            </v-col>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="password_new_confirm"
                                    label="Confirm New Password"
                                    vid="password_new_confirm"
                                    rules="required|password:@password_new"
                                    :type="typeFor('password_new_confirm')"
                                    :append-icon="
                                        iconFor('password_new_confirm')
                                    "
                                    @click:append="
                                        toggle('password_new_confirm')
                                    "
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="mx-2 pb-4 pt-0">
                        <v-btn
                            type="submit"
                            color="primary"
                            :loading="isSaving"
                            :block="$vuetify.breakpoint.smAndDown"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </a-form>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver } from 'vee-validate';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

@Component({
    components: {
        AForm,
        ATextInput
    }
})
export default class Security extends Vue {
    $refs!: {
        form: InstanceType<typeof AForm>;
    };

    password_old = '';
    password_new = '';
    password_new_confirm = '';

    showOldPwd = false;
    showNewPwd = false;
    showNewConfirmPwd = false;

    isSaving = false;

    inputs: Record<string, { visible: boolean }> = {
        password_old: {
            visible: false
        },
        password_new: {
            visible: false
        },
        password_new_confirm: {
            visible: false
        }
    };

    get observer() {
        return this.$refs.form.$refs.observer as unknown as InstanceType<
            typeof ValidationObserver
        >;
    }

    typeFor(id: string) {
        if (this.inputs[id]) {
            return this.inputs[id].visible ? 'text' : 'password';
        }
    }

    iconFor(id: string) {
        if (this.inputs[id]) {
            return this.inputs[id].visible ? 'eye-slash' : 'eye';
        }
    }

    toggle(id: string) {
        if (this.inputs[id]) {
            this.inputs[id].visible = !this.inputs[id].visible;
        }
    }

    setSaving(isSaving = true) {
        this.$emit('saving', isSaving);
        this.isSaving = isSaving;
    }

    getDataToSave() {
        return (({ password_old, password_new, password_new_confirm }) => ({
            password_old,
            password_new,
            password_new_confirm
        }))(this);
    }

    save() {
        this.setSaving();

        const url = '/users/password_update';

        return this.$http
            .post(url, this.getDataToSave())
            .then(({ data }) => {
                if (!data.meta.success) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save Security data. Please check the form for errors.'
                    );

                    this.setErrors(data.meta.errors);

                    return false;
                }

                this.onSave();

                this.$store.dispatch(
                    'notification/success',
                    'Security data updated'
                );
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    setErrors(errors?: Record<string, Record<string, string>>) {
        if (this.observer && errors) {
            for (const [field, errorsPerField] of Object.entries(errors)) {
                for (const msg of Object.values(errorsPerField)) {
                    this.observer.setErrors({
                        [field]: msg
                    });
                }
            }
        }
    }

    onSave() {
        this.password_old = '';
        this.password_new = '';
        this.password_new_confirm = '';

        this.observer?.reset();
    }
}
</script>
