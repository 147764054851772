import type { QuickLinksData, QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (data: QuickLinksData) => {
    const links: QuickLinkData[] = [];

    if (data.isPressCable) {
        links.push(LinksPool.alpha_stacker_training);
    } else {
        links.push(LinksPool.get_affiliate_links);
    }

    if (data.hasTraining) {
        links.push(LinksPool.training);
    }

    return links;
};
