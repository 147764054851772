<template>
    <v-list-item
        v-if="link.copy"
        v-clipboard:success="copy"
        v-clipboard:copy="copyPath"
        link
    >
        <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="link.hint">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">info-circle</v-icon>
                </template>
                <span>
                    {{ link.hint }}
                </span>
            </v-tooltip>
        </v-list-item-icon>
        <v-list-item-icon>
            <v-tooltip bottom :close-delay="copied ? timeout : 0">
                <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">copy</v-icon>
                </template>
                <span>
                    {{ copyTooltip }}
                </span>
            </v-tooltip>
        </v-list-item-icon>
    </v-list-item>
    <v-list-item
        v-else-if="link.modal_id"
        @click="openModalById(link.modal_id)"
    >
        <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="link.hint">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">info-circle</v-icon>
                </template>
                <span>
                    {{ link.hint }}
                </span>
            </v-tooltip>
        </v-list-item-icon>
    </v-list-item>
    <v-list-item v-else :href="link.href" :to="link.to">
        <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="link.hint">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">info-circle</v-icon>
                </template>
                <span>
                    {{ link.hint }}
                </span>
            </v-tooltip>
        </v-list-item-icon>
    </v-list-item>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { QuickLinkData } from '@/types/User';

const QuickLinkProps = Vue.extend({
    name: 'QuickLink',
    props: {
        link: {
            type: Object as PropType<QuickLinkData>,
            default(): QuickLinkData | null {
                return null;
            }
        }
    }
});

@Component
export default class QuickLink extends QuickLinkProps {
    timeout = 2000;

    get copyPath() {
        return window.location.origin + this.link.href;
    }

    get copyTooltip() {
        return this.copied
            ? `${this.link.copy} Copied`
            : `Click to copy ${this.link.copy}`;
    }

    copied = false;

    copy() {
        this.$emit('copying', true);

        this.copied = true;

        setTimeout(() => {
            this.copied = false;
            this.$emit('copying', false);
        }, this.timeout);
    }

    openModalById(modalId: string) {
        this.$store.dispatch('modal/open', modalId);
    }
}
</script>
