import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"py-0",class:{
        'px-0': _vm.$vuetify.breakpoint.smAndDown,
        'px-4': _vm.$vuetify.breakpoint.mdAndUp
    }},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-h6 font-weight-bold mb-2"},[_vm._v("Clients")]),_c('client-selector',{attrs:{"clients":_vm.clients}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }