import type { QuickLinksData, QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (data: QuickLinksData) => {
    const links: QuickLinkData[] = [];

    if (data.isMunchWeb) {
        links.push(LinksPool.get_affiliate_links, LinksPool.admin_reports);
    }

    links.push(LinksPool.training);

    if (data.isPressCable || data.isAnnouncementEngine) {
        links.push(
            LinksPool.press_release_admin,
            LinksPool.author_requests,
            LinksPool.vid_bullet_admin,
            LinksPool.press_release_authors,
            LinksPool.unsubscribe_emails,
            LinksPool.re_check_distribution_links,
            LinksPool.review_emails
        );
    }

    links.push(
        LinksPool.generated_keyword_rankings,
        LinksPool.video_approval,
        LinksPool.blog_post_approval,
        LinksPool.search_news_articles
    );

    return links;
};
