import type { /*QuickLinksData,*/ QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (/*data: QuickLinksData*/) => {
    const links: QuickLinkData[] = [];

    links.push(LinksPool.training);

    return links;
};
