<template>
    <v-container
        class="py-0"
        :class="{
            'px-0': $vuetify.breakpoint.smAndDown,
            'px-4': $vuetify.breakpoint.mdAndUp
        }"
    >
        <v-row>
            <v-col cols="12" md="6">
                <div class="text-h6 font-weight-bold mb-2">Clients</div>
                <client-selector :clients="clients" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { ClientSelector } from '@/components/ClientSelector';

import type { ClientUser } from '@/types/User';

const CompaniesClientSelectorProps = Vue.extend({
    name: 'CompaniesClientSelector',
    props: {
        clients: {
            type: Array as PropType<ClientUser[]>,
            default() {
                return [];
            }
        }
    }
});

@Component({
    components: {
        ClientSelector
    }
})
export default class CompaniesClientSelector extends CompaniesClientSelectorProps {}
</script>
