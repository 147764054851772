import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isEditing)?_c('router-view'):(_vm.isLoading)?_c('section-loading'):_c(VCard,{staticClass:"mb-2",class:{ 'px-5 py-4 mb-5': _vm.$vuetify.breakpoint.mdAndUp }},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VCardTitle,[_vm._v(" Companies "),_c(VSpacer),_c(VBtn,{staticClass:"primary mb-n1",attrs:{"title":"Add Company"},on:{"click":_vm.create}},[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp,"small":""}},[_vm._v(" plus ")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? 'Add Company' : null)+" ")],1)],1),_c(VCardText,{class:{ 'px-0': _vm.$vuetify.breakpoint.mdAndUp }},[(_vm.isReseller)?_c('companies-client-selector',{attrs:{"clients":_vm.clients}}):_vm._e(),_c('Most-used',{attrs:{"companies":_vm.mostUsed},on:{"select":_vm.edit}}),_c('companies-filter',{attrs:{"user-id":_vm.userId}}),_c('companies-list',{attrs:{"companies":_vm.companies},on:{"create":_vm.create,"edit":_vm.edit,"drop":_vm.drop}}),_c('paging',{staticClass:"px-4",attrs:{"total":_vm.total,"default-per-page":_vm.perPage}}),_c('company-delete-dialog',{attrs:{"id":"confirm-delete-company","company":_vm.confirmCompany},on:{"confirm":_vm.confirmDelete}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }