import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.mostUsedExist)?_c(VContainer,{staticClass:"py-0",class:{
        'px-0': _vm.$vuetify.breakpoint.smAndDown,
        'px-4': _vm.$vuetify.breakpoint.mdAndUp
    }},[_c(VRow,{class:{
            'mb-2 mt-0': _vm.$vuetify.breakpoint.mdAndUp,
            'mb-4': _vm.$vuetify.breakpoint.smAndDown
        }},[_c(VCol,{staticClass:"ma-n1 pa-0 mb-n3",attrs:{"cols":"12"}},[_c(VSubheader,{staticClass:"text-uppercase"},[_vm._v("Most Used")])],1),_vm._l((_vm.renderableMostUsed),function(company){return _c(VCol,{key:company.id,class:{ 'mb-0 mt-2 py-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":_vm.$vuetify.breakpoint.mdAndDown ? 6 : 4}},[_c('company-card',{staticStyle:{"cursor":"pointer"},attrs:{"company":company,"flat":""},nativeOn:{"click":function($event){return _vm.select(company)}}})],1)})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }