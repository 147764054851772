import type { /* QuickLinksData, */ QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (/* data: QuickLinksData */) => {
    const links: QuickLinkData[] = [];

    links.push(
        {
            ...LinksPool.press_release_authors,
            title: 'Author Requests'
        },
        LinksPool.generated_keyword_rankings
    );
    return links;
};
