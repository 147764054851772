<template>
    <section-loading v-if="isLoading" />
    <v-card
        v-else
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-card-title>Payments</v-card-title>
        <v-card-text :class="{ 'px-0': $vuetify.breakpoint.mdAndUp }">
            <div v-if="paymentsExist">
                <v-simple-table v-if="$vuetify.breakpoint.mdAndUp">
                    <template #default>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Amount</th>
                                <th class="text-right">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(payment, i) in availablePayments"
                                :key="i"
                            >
                                <td>
                                    {{ payment.product.product_name }}
                                </td>
                                <td>
                                    ${{ payment.amount }}
                                    <v-chip
                                        v-if="payment.refund_transaction_id"
                                        class="ml-2"
                                        small
                                    >
                                        Refunded
                                        <span v-if="notFullRefund(payment)">
                                            :&nbsp;${{ payment.refund_amount }}
                                        </span>
                                    </v-chip>
                                </td>
                                <td class="text-right">
                                    {{ formatDate(payment.timestamp) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <template v-else>
                    <v-list-item
                        v-for="(payment, i) in availablePayments"
                        :key="i"
                        three-line
                        class="px-0"
                    >
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap py-2">
                                {{ payment.product.product_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="pb-2">
                                <strong>${{ payment.amount }}</strong>
                                <v-chip
                                    v-if="payment.refund_transaction_id"
                                    class="ml-2"
                                    small
                                >
                                    Refunded
                                    <span v-if="notFullRefund(payment)">
                                        :&nbsp;${{ payment.refund_amount }}
                                    </span>
                                </v-chip>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="caption">
                                {{ formatDate(payment.timestamp) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <a
                    href="/sales/my_payments"
                    :class="{ 'pl-4': $vuetify.breakpoint.mdAndUp }"
                >
                    See All Payments
                </a>
            </div>
            <p v-else class="pl-4">You have no payments</p>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import SectionLoading from './SectionLoading.vue';

import type { PaymentsData, Payment } from '@/types/Panel';

@Component({
    components: {
        SectionLoading
    }
})
export default class Payments extends Vue {
    payments: PaymentsData = {
        available: false,
        payments: []
    };

    isLoading = true;

    mounted() {
        this.setLoading();

        return this.getUserData()
            .then(({ data }) => {
                if (data) {
                    this.payments = data.payments;
                }
            })
            .finally(this.setLoading.bind(this, false));
    }

    setLoading(isLoading = true) {
        this.$emit('loading', isLoading);
        this.isLoading = isLoading;
    }

    get paymentsExist() {
        return Boolean(this.payments.payments.length);
    }

    get availablePayments() {
        // legacy products used before as permissions
        return this.payments.payments.filter((payment: Payment) =>
            Boolean(payment.product.name && payment.amount)
        );
    }

    formatDate(value: number) {
        return value ? this.$dayjs.unix(value).format('ll') : '';
    }

    getUserData() {
        return this.$http
            .get('/users/panel')
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    notFullRefund(payment: Payment) {
        return (
            payment.refund_transaction_id &&
            payment.amount !== payment.refund_amount
        );
    }
}
</script>
