import type { QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default () => {
    const links: QuickLinkData[] = [];

    links.push(LinksPool.training);

    return links;
};
