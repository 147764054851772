import Admin from './Admin';
import Affiliate from './Affiliate';
import Agent from './Agent';
import Author from './Author';
import Customer from './Customer';
import CustomerPendingAffiliate from './CustomerPendingAffiliate';
import Development from './Development';
import Editor from './Editor';
import Reseller from './Reseller';
import ResellerClient from './ResellerClient';
import SuperAdmin from './SuperAdmin';
import TrainingCourseBuyer from './TrainingCourseBuyer';

export default {
    Admin,
    Affiliate,
    Agent,
    Author,
    Customer,
    CustomerPendingAffiliate,
    Development,
    Editor,
    Reseller,
    ResellerClient,
    SuperAdmin,
    TrainingCourseBuyer
};
