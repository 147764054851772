import type { QuickLinksData, QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (data: QuickLinksData) => {
    const links: QuickLinkData[] = [];

    if (data.hasAsaa) {
        links.push({
            ...LinksPool.training,
            title: 'AlphaStacker Training'
        });
    }

    if (data.hasTraining) {
        links.push(LinksPool.training);
    }

    return links;
};
