import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.hasLinks)?_c(VMenu,{attrs:{"transition":"slide-y-transition","offset-y":"","bottom":"","left":"","close-on-content-click":!_vm.keepOpen},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_vm._v(" Quick Links "),_c(VIcon,{attrs:{"right":"","x-small":""}},[_vm._v("chevron-down")])],1)]}}],null,false,1234996175),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VList,{attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown}},_vm._l((_vm.links),function(link,index){return _c('span',{key:index},[_c('quick-link',{attrs:{"link":link},on:{"copying":_vm.copying}}),(index !== _vm.links.length - 1)?_c(VDivider):_vm._e()],1)}),0)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }