<template>
    <v-row class="caption text-uppercase ma-0">
        <v-col cols="1" class="pl-2 pr-0" />
        <v-col cols="3" class="pl-2 pr-0">
            <a @click.prevent="sortBy('name')">
                Name
                <v-icon
                    v-if="isOrderedBy('name') && !isDesc"
                    small
                    color="primary"
                    class="pl-2 mt-n1"
                >
                    caret-up
                </v-icon>
                <v-icon
                    v-else-if="isOrderedBy('name') && isDesc"
                    small
                    color="primary"
                    class="pl-2 mt-n1"
                >
                    caret-down
                </v-icon>
            </a>
        </v-col>
        <v-col cols="3" class="pl-2 pr-0">Contact name</v-col>

        <v-col cols="4" class="pl-2 pr-0">Address</v-col>
        <v-col cols="1" />
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CompaniesListHeader extends Vue {
    defaultOrderBy = 'name';

    get orderBy() {
        return this.$route.query.sort;
    }

    get isDesc() {
        return this.$route.query.asc === '0';
    }

    isOrderedBy(column: string) {
        return this.orderBy === column || this.defaultOrderBy === column;
    }

    sortBy(column: string) {
        this.$router.push({
            path: this.$route.path,
            query: {
                ...this.$route.query,
                sort: column,
                asc: this.isDesc ? '1' : '0'
            }
        });
    }
}
</script>
