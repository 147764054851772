<template>
    <v-container
        class="py-0"
        :class="{
            'px-0': $vuetify.breakpoint.smAndDown,
            'px-4': $vuetify.breakpoint.mdAndUp
        }"
    >
        <v-row>
            <v-col cols="12" md="6">
                <a-text-input
                    ref="input"
                    v-model="input"
                    label="Search By Company Name"
                    field="Company Name"
                    rules="min:4"
                    clearable
                    @input="update"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { debounce } from '@/utils/helpers';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

const CompaniesFilterProps = Vue.extend({
    name: 'CompaniesFilter',
    props: {
        userId: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component({
    components: {
        ATextInput
    }
})
export default class CompaniesFilter extends CompaniesFilterProps {
    $refs!: {
        input: InstanceType<typeof ATextInput>;
    };

    input = '';

    get term(): string {
        return (
            (Array.isArray(this.$route.query.term)
                ? this.$route.query.term[0]
                : this.$route.query.term) || ''
        );
    }

    created() {
        this.$watch(
            () => this.$route.query,
            () => {
                this.sync();
            }
        );
    }

    mounted() {
        this.sync();
    }

    sync() {
        this.input = this.term;
    }

    debouncedFilter = debounce(
        (value: string) => this.verifyAndFilter(value),
        500
    );

    async update(value: string) {
        if (value) {
            this.debouncedFilter(value);
        } else {
            // remove filter
            this.filter(value);
        }
    }

    async verifyAndFilter(value: string) {
        const { valid } = await this.canSearchFor(value);

        if (valid) {
            this.filter(value);
        }
    }

    filter(term?: string) {
        this.$router
            .push({
                path: this.$route.path,
                query: {
                    user_id: this.userId || void 0, // follow Client, if any
                    term: term || void 0
                }
            })
            .catch(() => {
                /* fail silently on duplicated queries */
            });

        this.$emit('filter', term || '');
    }

    async canSearchFor(term: string) {
        return this.$refs.input.$refs.provider.validate(term);
    }
}
</script>
