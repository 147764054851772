import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mb-2",class:{ 'px-5 py-4 mb-5': _vm.$vuetify.breakpoint.mdAndUp }},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","lg":"9","xl":"6"}},[_c('a-form',{attrs:{"submit":_vm.save}},[_c(VCardTitle,{staticClass:"mb-4"},[_vm._v("Social Media Links")]),_c(VCardSubtitle,[_vm._v(" Social media links will appear on all pages in your site's footer ")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('a-text-input',{attrs:{"label":"Facebook","rules":"url","autocomplete":"off","prepend-icon":"fab fa-facebook-f"},model:{value:(_vm.facebook),callback:function ($$v) {_vm.facebook=$$v},expression:"facebook"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('a-text-input',{attrs:{"label":"X handle","rules":"url","autocomplete":"off","prepend-icon":"fab fa-x-twitter"},model:{value:(_vm.twitter),callback:function ($$v) {_vm.twitter=$$v},expression:"twitter"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('a-text-input',{attrs:{"label":"Linkedin","rules":"url","autocomplete":"off","prepend-icon":"fab fa-linkedin"},model:{value:(_vm.linkedin),callback:function ($$v) {_vm.linkedin=$$v},expression:"linkedin"}})],1)],1)],1),_c(VCardActions,{staticClass:"mx-2 pb-4 pt-0"},[_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.isSaving,"block":_vm.$vuetify.breakpoint.smAndDown}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }