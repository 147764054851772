<template>
    <v-container
        v-if="companiesExist && $vuetify.breakpoint.mdAndUp"
        class="px-4"
    >
        <companies-list-header />
        <company-row
            v-for="(company, index) in companies"
            :key="'company-row-' + company.id + '-' + index"
            :company="company"
            @edit="edit(company)"
            @drop="drop(company)"
        />
    </v-container>
    <v-container v-else-if="companiesExist" class="px-0">
        <company-card
            v-for="(company, index) in companies"
            :key="'company-card-' + company.id + '-' + index"
            :company="company"
            class="mb-2"
            flat
            style="cursor: pointer"
            @click.native="edit(company)"
        />
    </v-container>
    <v-container v-else class="px-0">
        <v-row class="mx-0">
            <v-col cols="12" class="d-flex justify-center pt-8 px-0">
                <span>
                    No companies found. You can
                    <a @click.prevent="create">start adding</a>
                    one!
                </span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import CompaniesListHeader from './CompaniesListHeader.vue';
import CompanyRow from './CompanyRow.vue';
import { CompanyCard } from '@/components/CompanyCard';

import type { Company } from '@/types/Company';

const CompaniesListProps = Vue.extend({
    name: 'CompaniesList',
    props: {
        companies: {
            type: Array as PropType<Partial<Company>[]>,
            default(): Company[] {
                return [];
            }
        }
    }
});

@Component({
    components: {
        CompaniesListHeader,
        CompanyRow,
        CompanyCard
    }
})
export default class CompaniesList extends CompaniesListProps {
    get companiesExist() {
        return Boolean(this.companies.length);
    }

    edit(company: Partial<Company>) {
        this.$emit('edit', company);
    }

    drop(company: Partial<Company>) {
        this.$emit('drop', company);
    }

    create() {
        this.$emit('create');
    }
}
</script>
