<template>
    <v-card
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row class="ma-0">
            <v-col cols="12" lg="9" xl="6" class="pa-0">
                <a-form :submit="save">
                    <v-card-title class="mb-4">Social Media Links</v-card-title>
                    <v-card-subtitle>
                        Social media links will appear on all pages in your
                        site's footer
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="facebook"
                                    label="Facebook"
                                    rules="url"
                                    autocomplete="off"
                                    prepend-icon="fab fa-facebook-f"
                                />
                            </v-col>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="twitter"
                                    label="X handle"
                                    rules="url"
                                    autocomplete="off"
                                    prepend-icon="fab fa-x-twitter"
                                />
                            </v-col>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="linkedin"
                                    label="Linkedin"
                                    rules="url"
                                    autocomplete="off"
                                    prepend-icon="fab fa-linkedin"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="mx-2 pb-4 pt-0">
                        <v-btn
                            type="submit"
                            color="primary"
                            :loading="isSaving"
                            :block="$vuetify.breakpoint.smAndDown"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </a-form>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import type { WhitelabelData } from '@/types/Whitelabel';

const ResellerProps = Vue.extend({
    name: 'ResellerSocialMedia',
    props: {
        reseller: {
            type: Object as PropType<Partial<WhitelabelData>>,
            default(): Partial<WhitelabelData> {
                return {};
            }
        }
    }
});

@Component({
    components: {
        AForm,
        ATextInput
    }
})
export default class ResellerSocialMedia extends ResellerProps {
    facebook = '';
    twitter = '';
    linkedin = '';

    isSaving = false;

    mounted() {
        const {
            facebook = '',
            twitter = '',
            linkedin = ''
        } = this.reseller.social_media || {};

        this.facebook = facebook;
        this.twitter = twitter;
        this.linkedin = linkedin;
    }

    save() {
        this.setSaving();

        return this.setUserData()
            .then(data => {
                if (!data?.meta?.success) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save Social Media Links. Please check the form for errors.'
                    );

                    return false;
                }

                this.$store.dispatch(
                    'notification/success',
                    'Social Media Links updated'
                );
            })
            .finally(this.setSaving.bind(this, false));
    }

    setSaving(isSaving = true) {
        this.$emit('saving', isSaving);
        this.isSaving = isSaving;
    }

    setUserData() {
        return this.$http
            .post('/users/reseller_social_media_setup', this.getDataToSave())
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    getDataToSave() {
        return (({ facebook, twitter, linkedin }) => ({
            facebook,
            twitter,
            linkedin
        }))(this);
    }
}
</script>
