import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mb-2",class:{ 'px-5 py-4 mb-5': _vm.$vuetify.breakpoint.mdAndUp }},[_c('a-form',{attrs:{"submit":_vm.save}},[_c(VCardTitle,{staticClass:"mb-4"},[_vm._v("Reseller Profile Image")]),_c(VCardSubtitle,[_vm._v(" This will appear on the your whitelabel about page ")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('a-media-uploader',{ref:"uploader",attrs:{"croppable":"","upload-cropped":"","can-rotate-image":"","allow-process":false,"options":_vm.options},on:{"addfile":_vm.onFileAdd,"uploaded":_vm.update}})],1),(_vm.hasImage)?_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c(VImg,{staticClass:"avatar-bordered",attrs:{"contain":"","width":"100%","max-height":"12em","src":_vm.image,"lazy-src":""}})],1):_c(VCol,{staticClass:"pt-1 pb-5",attrs:{"cols":"12","md":"6"}},[_c(VListItemAvatar,{staticClass:"avatar-bordered",attrs:{"height":"100%","width":"100%"}},[_c(VIcon,{attrs:{"large":"","color":"grey lighten-2"}},[_vm._v("image")])],1)],1)],1)],1),_c(VCardActions,{staticClass:"mx-2 pb-4 pt-0"},[_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.isSaving,"block":_vm.$vuetify.breakpoint.smAndDown}},[_vm._v(" Save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }