<template>
    <router-view v-if="isPortal" />
    <section-loading v-else-if="isLoading" />
    <div v-else>
        <Reseller :reseller="reseller" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import SectionLoading from '../SectionLoading.vue';

import { Reseller } from './Reseller';

import type { WhitelabelData } from '@/types/Whitelabel';

@Component({
    components: {
        SectionLoading,
        Reseller
    }
})
export default class Whitelabel extends Vue {
    group = 'Visitor';

    reseller: WhitelabelData = {
        id: 0,
        business_name: '',
        product_name: '',
        reseller_booking_url: '',
        reseller_currency: '',
        subdomain: '',
        website: null,
        reseller_logo: null,
        reseller_profile_image: null,
        social_media: null,
        currency_symbol: '',
        currencies: {},
        pricing: {
            reseller_client_authoring_credit_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_recurring_authoring_credit_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_snapshot_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_premium_amp_credit_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_recurring_premium_amp_credit_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_defi_credit_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_recurring_defi_credit_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_blow_pack_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_recurring_blow_pack_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_msn_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_recurring_msn_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_content_campaign_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_recurring_content_campaign_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            },
            reseller_client_recurring_four_content_campaigns_price: {
                value: 0,
                minimum: 0,
                recommended: 0
            }
        },
        credits: {
            premium: 0,
            crypto: 0,
            blow: 0,
            msn: 0,
            financial: 0,
            authoring: 0,
            distribution: 0,
            presscable: 0,
            available: true
        },
        hasPremiumPurchase: false
    };

    isLoading = true;

    get hasPaymentNotification() {
        return new URL(window.location.href).searchParams.has('updatePrices');
    }

    get isPortal() {
        return this.$route.params.subsection === 'portal';
    }

    mounted() {
        this.setLoading();

        return this.getUserData()
            .then(({ data }) => {
                if (data) {
                    this.group = data.group;
                    this.reseller = {
                        ...data.reseller,
                        ...data.user,
                        currencies: data.currencies,
                        hasPremiumPurchase: data.credits.hasPremiumPurchase,
                        credits: data.credits
                    };
                }
            })
            .finally(this.setLoading.bind(this, false));
    }

    setLoading(isLoading = true) {
        this.$emit('loading', isLoading);
        this.isLoading = isLoading;
    }

    getUserData() {
        return this.$http
            .get('/users/panel')
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }
}
</script>
