import type { QuickLinksData, QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (data: QuickLinksData) => {
    const links: QuickLinkData[] = [];

    const users = data.isPressCable ? 'Users' : 'Agents';

    if (data.isMunchWeb) {
        links.push(
            LinksPool.get_affiliate_links,
            LinksPool.manage_temporary_sale_pages
        );
    }

    links.push({
        ...LinksPool.manage_users,
        title: `Manage ${users}`
    });

    if (data.hasTraining) {
        links.push(LinksPool.training);
    }

    if (data.isPressCable) {
        links.push(
            LinksPool.press_release_admin,
            LinksPool.press_release_authors
        );
    }

    return links;
};
