<template>
    <v-hover v-slot="{ hover }">
        <v-row
            class="amp-company-row ma-0 mb-2"
            :class="{
                'concrete-solid': hover,
                'main-background': !hover,
                'px-2': $vuetify.breakpoint.mdAndDown
            }"
        >
            <v-col cols="1" :class="{ 'px-0': $vuetify.breakpoint.mdAndDown }">
                <v-avatar rounded color="white">
                    <v-img
                        v-if="logo"
                        max-width="50"
                        max-height="50"
                        :src="logo"
                    />
                    <v-icon v-else size="30" color="grey lighten-2">
                        image
                    </v-icon>
                </v-avatar>
            </v-col>
            <v-col cols="3" class="pl-2 pr-0 ma-auto font-weight-medium">
                <text-truncated :text="company.name" />
            </v-col>
            <v-col cols="3" class="pl-2 pr-0 ma-auto">
                <text-truncated :text="company.contact_name" />
            </v-col>
            <v-col cols="4" class="pl-2 pr-0 ma-auto">
                <text-truncated :text="company.full_address" />
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 py-0 ma-auto" align="right">
                <company-row-actions
                    :key="company.id"
                    :company="company"
                    @edit="edit"
                    @drop="drop"
                />
            </v-col>
        </v-row>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { TextTruncated } from '@/components/TextTruncated';
import CompanyRowActions from './CompanyRowActions.vue';

import type { Company } from '@/types/Company';

const CompanyRowProps = Vue.extend({
    name: 'CompanyRow',
    props: {
        company: {
            type: Object as PropType<Partial<Company>>,
            default(): Company | null {
                return null;
            }
        }
    }
});

@Component({
    components: {
        TextTruncated,
        CompanyRowActions
    }
})
export default class CompanyRow extends CompanyRowProps {
    get logo() {
        if (this.company) {
            const media = this.company.media_resources;

            if (media && media.length) {
                return media[0].media_file.relative_filename;
            }
        }
        return false;
    }

    edit(company: Company) {
        this.$emit('edit', company);
    }

    drop(company: Company) {
        this.$emit('drop', company);
    }
}
</script>

<style lang="scss">
.amp-company-row {
    border-radius: 5px;
}
</style>
