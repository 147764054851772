<template>
    <v-menu
        v-if="hasLinks"
        v-model="visible"
        transition="slide-y-transition"
        offset-y
        bottom
        left
        :close-on-content-click="!keepOpen"
    >
        <template #activator="{ on, attrs }">
            <v-btn plain v-bind="attrs" v-on="on">
                Quick Links
                <v-icon right x-small>chevron-down</v-icon>
            </v-btn>
        </template>
        <v-list :dense="$vuetify.breakpoint.smAndDown">
            <span v-for="(link, index) in links" :key="index">
                <quick-link :link="link" @copying="copying" />
                <v-divider v-if="index !== links.length - 1" />
            </span>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import QuickLink from './QuickLink.vue';
import links from './links';

import type { QuickLinksData, QuickLinkData } from '@/types/User';

const QuickLinksProps = Vue.extend({
    name: 'QuickLinks',
    props: {
        profile: {
            type: Object as PropType<QuickLinksData>,
            default(): QuickLinksData | null {
                return null;
            }
        }
    }
});

@Component({
    components: {
        QuickLink
    }
})
export default class QuickLinks extends QuickLinksProps {
    visible = false;

    get hasLinks() {
        return this.links.length;
    }

    get links() {
        if (this.profile !== null) {
            const availableLinks = links as {
                [Property in keyof typeof links]: (
                    data: QuickLinksData
                ) => QuickLinkData[];
            };

            const role = this.$store.state.user.role as keyof typeof links;

            if (role in availableLinks) {
                return availableLinks[role](this.profile);
            }
        }

        return [];
    }

    isCopying = false;

    get keepOpen() {
        return this.isCopying;
    }

    copying(isCopying: boolean) {
        this.isCopying = Boolean(isCopying);

        this.visible = this.isCopying;
    }
}
</script>
