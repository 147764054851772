import type { QuickLinksData, QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (data: QuickLinksData) => {
    const links: QuickLinkData[] = [];

    if (data.isMunchWeb) {
        links.push(LinksPool.get_affiliate_links, LinksPool.admin_reports);
    }

    links.push(LinksPool.author_requests, LinksPool.development);

    if (data.isStagingServer) {
        links.push(LinksPool.mock_interwire_admin);
    }

    if (data.isPressCable) {
        links.push(
            LinksPool.press_release_admin,
            LinksPool.vid_bullet_admin,
            LinksPool.press_release_authors,
            LinksPool.email_marketing,
            LinksPool.training,
            LinksPool.unsubscribe_emails,
            LinksPool.review_emails
        );
    }

    links.push(
        LinksPool.re_check_distribution_links,
        LinksPool.generated_keyword_rankings,
        LinksPool.video_approval,
        LinksPool.blog_post_approval,
        LinksPool.search_news_articles
    );

    return links;
};
