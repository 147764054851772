<template>
    <v-card
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <a-form :submit="save">
            <v-card-title class="mb-4">Reseller Profile Image</v-card-title>
            <v-card-subtitle>
                This will appear on the your whitelabel about page
            </v-card-subtitle>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="6">
                        <a-media-uploader
                            ref="uploader"
                            croppable
                            upload-cropped
                            can-rotate-image
                            :allow-process="false"
                            :options="options"
                            @addfile="onFileAdd"
                            @uploaded="update"
                        />
                    </v-col>
                    <v-col v-if="hasImage" cols="12" md="6" class="text-center">
                        <v-img
                            contain
                            width="100%"
                            max-height="12em"
                            :src="image"
                            lazy-src=""
                            class="avatar-bordered"
                        />
                    </v-col>
                    <v-col v-else cols="12" md="6" class="pt-1 pb-5">
                        <v-list-item-avatar
                            height="100%"
                            width="100%"
                            class="avatar-bordered"
                        >
                            <v-icon large color="grey lighten-2">image</v-icon>
                        </v-list-item-avatar>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="mx-2 pb-4 pt-0">
                <v-btn
                    type="submit"
                    color="primary"
                    :loading="isSaving"
                    :block="$vuetify.breakpoint.smAndDown"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </a-form>
    </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { AForm } from '@/components/AForm';
import { AMediaUploader } from '@/components/AForm/Inputs/AMediaUploader';

import type { WhitelabelData } from '@/types/Whitelabel';
import type { MediaFile } from '@/types/MediaFile';

const ResellerProps = Vue.extend({
    name: 'ResellerProfileImage',
    props: {
        reseller: {
            type: Object as PropType<Partial<WhitelabelData>>,
            default(): Partial<WhitelabelData> {
                return {
                    reseller_profile_image: null
                };
            }
        }
    }
});

@Component({
    components: {
        AForm,
        AMediaUploader
    }
})
export default class ResellerProfileImage extends ResellerProps {
    $refs!: {
        uploader: InstanceType<typeof AMediaUploader>;
    };

    isSaving = false;

    cropperOpener: ReturnType<typeof setTimeout> | void = void 0;

    media: Partial<MediaFile> | null = null;

    get hasImage() {
        return Boolean(this.image);
    }

    get image() {
        return (
            this.media?.relative_filename ||
            this.reseller.reseller_profile_image?.media_file.relative_filename
        );
    }

    get options() {
        return {
            media_resource: {
                reseller_profile_id: this.reseller.id
            },
            title: this.reseller.business_name
        };
    }

    update({ relative_filename, id }: Partial<MediaFile>) {
        if (id) {
            this.media = {
                id,
                relative_filename
            };
        }
    }

    save() {
        this.setSaving();

        this.$refs.uploader.tryToUpload().finally(() => {
            this.setSaving(false);
        });
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    onFileAdd() {
        if (
            this.$refs.uploader.getFiles().length !==
            Object.keys(this.$refs.uploader.cropData).length
        ) {
            const files = this.$refs.uploader.getFiles();

            files.forEach(file => {
                if (this.cropperOpener) {
                    clearTimeout(this.cropperOpener);
                }

                this.cropperOpener = setTimeout(() => {
                    this.$refs.uploader.tryOpenCropper(file.id);
                }, 500);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.avatar-bordered {
    border: 1px dashed $grey-medium-light;
    border-radius: 7px;
}
</style>
