import type { /*QuickLinksData,*/ QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (/*data: QuickLinksData*/) => {
    const links: QuickLinkData[] = [];

    links.push(
        LinksPool.author_requests,
        LinksPool.generated_keyword_rankings,
        LinksPool.video_approval,
        LinksPool.blog_post_approval,
        LinksPool.search_news_articles
    );

    return links;
};
