<template>
    <modal :id="id" title="Delete Company">
        <template #default>
            <p>
                Are you sure you want to delete
                <strong>{{ company.name }}</strong>
                company?
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="cancel">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="confirm"
            >
                Delete
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Modal } from '@/components/Modal';

import type { Company } from '@/types/Company';

const CompanyDeleteDialogProps = Vue.extend({
    name: 'CompanyDeleteDialog',
    props: {
        id: {
            type: String,
            required: true
        },
        company: {
            type: Object as PropType<Partial<Company>>,
            default() {
                return {
                    name: ''
                };
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class CompanyDeleteDialog extends CompanyDeleteDialogProps {
    confirm() {
        this.$emit('confirm', true);
    }

    cancel() {
        this.$emit('confirm', false);
    }
}
</script>
